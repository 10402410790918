export const StepStatus = {
    Input: 0,
    Payment: 1,
    Completed: 3
}

export const STEPS = [
    { title: 'input' },
    { title: 'payment' },
    { title: 'complete' },
]

export const LANGUAGES = [
    { label: "🇬🇧 English", code: "en" },
    { label: "🇯🇵 日本語", code: "ja" },
    { label: "🇨🇳 中国", code: "zh" },
    { label: "🇰🇷 韓国", code: "ko" }
];