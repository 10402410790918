import {Language} from "./Language";
import {Link} from "react-router-dom";

export const Header = () => {
    return (
        <nav className="d-flex justify-content-between align-items-center mb-5 w-100 px-3 py-2 shadow-sm">
            <h5 className="text-center" style={{'color': '#d5408c'}}>
                <Link to="/registration">
                    <span className="fw-bold">River </span>CRUISE
                </Link>
            </h5>
            <div className="d-flex">
                <Link to="/location" className="me-2">
                    <small>🌐</small>
                </Link>
                <Language/>
            </div>
        </nav>
    )
}
