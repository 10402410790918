import {Box, Container} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export const Location = () => {
    const { t } = useTranslation()

    return (
        <Container>
            <Box className="card rounded-0 shadow mt-5">
                <p className="fs-2 text-center my-3">{t('location.title')}</p>
                <Box className="card-body px-4 pb-5 pt-0">
                    <Box>
                        <p className="mb-1">{t('location.location')}: <span className="text-secondary">〒810-0002 福岡県福岡市中央区西中洲6-6</span></p>
                        <p>{t('location.phone')}: <span className="text-secondary">080-5215-6555</span></p>
                    </Box>
                    <iframe
                        title="Location"
                        className="w-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6647.029422524855!2d130.40222567632185!3d33.59194724189384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35419193c10eb363%3A0xa4c2041edfe51811!2sNakagawa%20River%20Cruise!5e0!3m2!1sen!2sjp!4v1713322734595!5m2!1sen!2sjp"
                        height="350"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </Box>
            </Box>
        </Container>
    )
}
