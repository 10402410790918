import moment from "moment/moment";
import {getPublicHoliday} from "../service/api";

export const isWeekend = (date) => {
    const dayOfWeek = moment(date).day()
    return (dayOfWeek === 6) || (dayOfWeek  === 0);
}

export const isPublicHoliday = (date) => {
    return new Promise((resolve) => {
        let publicHolidays = []

        getPublicHoliday(moment(date).year())
            .then(res => {
                publicHolidays = res
            })
            .catch(err => {
                console.error('Failed To Fetch Public Holidays', err)
            })
            .finally(() => {
                const isPublicHoliday = publicHolidays.hasOwnProperty(date);
                resolve(isWeekend(date) || isPublicHoliday);
            })
    })
}