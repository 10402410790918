import moment from "moment";

const initialState = {
    name: '',
    email: '',
    phone: '',
    course: 0,
    date: moment().format('YYYY-MM-DD'),
    time: '',
    adult: 0,
    child: 0,
    remarks: '',
    amount: 0,
    ac_code: '',
    idr_code: ''
};

const reservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_RESERVATION':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default:
            return state;
    }
};

export default reservationReducer;