import {
    Box,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Stat,
    StatGroup,
    StatHelpText,
    StatLabel,
    StatNumber
} from "@chakra-ui/react";
import {EmailIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import {StepStatus} from "../config/const";
import {checkout, sendEmail} from "../service/api";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import NotificationService from "../service/notification";
import {ErrorMessage} from "../components/ErrorMessage";
import Logger from "../utils/logger";

const logger = new Logger('Page|Confirmation')

export const Confirmation = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const reservation = useSelector(state => state.reservation)
    const course = useSelector(state => state.course.course)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { i18n, t } = useTranslation()

    const {sendNotification} = NotificationService()

    useEffect(() => {
        if (!reservation.name) {
            logger.error('Reservation State Expired')
            navigate('/registration')
        }
    }, [navigate, reservation.name])

    const sendConfirmationEmail = () => {
        if (!reservation.email) {
            setError('email-required')
            return
        } else if (!/\S+@\S+\.\S+/.test(reservation.email)) {
            setError('email-invalid')
            return
        }

        setIsLoading(true)

        checkout(reservation)
            .then(data => {
                logger.info('Checkout Successful: ', data)
                return sendEmail(data, reservation.email, i18n.resolvedLanguage)
            })
            .then(_ => {
                logger.info('Email Sent Successfully')
                dispatch({type: 'SET_STEP', payload: StepStatus.Payment})
                navigate('/notice')
            })
            .catch(error => {
                logger.error('Checkout Error || Email Sent Error', error)
                sendNotification(t('confirmation.error.email-send-failed'), 'error')
            })
            .finally(_ => {
                setIsLoading(false)
            })
    }

    const edit = () => {
        dispatch({ type: 'SET_STEP', payload: StepStatus.Input })
        navigate('/registration')
    }

    return (
        <div className="confirmation py-3">
            <div className="d-flex justify-content-end mb-3">
                <Button borderRadius={0} paddingX={5} size='sm' color='gray' onClick={edit}>
                    { t('confirmation.edit-btn') }
                </Button>
            </div>
            <StatGroup>
                <Stat>
                    <StatLabel>{ t('reservation.name') }</StatLabel>
                    <StatHelpText>{reservation.name}</StatHelpText>
                    <StatLabel>{ t('reservation.phone') }</StatLabel>
                    <StatHelpText>{reservation.phone}</StatHelpText>
                    <StatLabel>{ t('reservation.course') }</StatLabel>
                    <StatHelpText>{course.name}</StatHelpText>
                </Stat>
                <Stat>
                    <StatLabel>{ t('reservation.date') }</StatLabel>
                    <StatHelpText>{reservation.date} {reservation.time}:00</StatHelpText>
                    <StatLabel>{ t('reservation.adult') }</StatLabel>
                    <StatHelpText>{reservation.adult}</StatHelpText>
                    <StatLabel>{ t('reservation.child') }</StatLabel>
                    <StatHelpText>{reservation.child}</StatHelpText>
                </Stat>
            </StatGroup>
            <StatGroup>
                <Stat>
                    <StatLabel>{ t('reservation.remarks') }</StatLabel>
                    <StatHelpText>{reservation.remarks}</StatHelpText>
                    <StatLabel>{ t('reservation.total') }</StatLabel>
                    <StatNumber>¥{reservation.amount}</StatNumber>
                </Stat>
            </StatGroup>
            <div className="email-form">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <EmailIcon color='pink.500'/>
                    </InputLeftElement>
                    <Input
                        borderRadius={0}
                        isInvalid={error !== ''}
                        type="text"
                        name="email"
                        value={reservation.email}
                        placeholder={ t('reservation.email') }
                        onChange={(e) => dispatch({
                            type: 'UPDATE_RESERVATION',
                            payload: { name: 'email', value: e.target.value }
                        })}
                    />
                    <InputRightElement w='150' className="d-none d-sm-block">
                        <Button
                            borderRadius={0}
                            colorScheme='pink'
                            isLoading={isLoading}
                            color='white'
                            loadingText={ t('confirmation.sending-btn') }
                            onClick={sendConfirmationEmail}
                        >
                            { t('confirmation.send-btn') }
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <ErrorMessage page='confirmation' error={error} />

                <Box className="mt-3 d-sm-none">
                    <Button
                        borderRadius={0}
                        width='100%'
                        colorScheme='pink'
                        isLoading={isLoading}
                        color='white'
                        loadingText={ t('confirmation.sending-btn') }
                        onClick={sendConfirmationEmail}
                    >
                        { t('confirmation.send-btn') }
                    </Button>
                </Box>
            </div>
        </div>
    )
}