import {Registration} from "./pages/Registration";
import {Navigate, Route, Routes} from "react-router-dom";
import {NotFound} from "./pages/NotFound";
import {Header} from "./components/Header";
import {loadStripe} from "@stripe/stripe-js";
import {Payment} from "./pages/Payment";
import {ThankYou} from "./pages/ThankYou";
import {Confirmation} from "./pages/Confirmation";
import {Box} from "@chakra-ui/react";
import {MailNotice} from "./pages/MailNotice";
import {RouteWrapper} from "./components/RouteWrapper";
import Logger from "./utils/logger";
import {Location} from "./pages/Location";

const logger = new Logger('App')

const stripe = loadStripe("pk_test_51Op1ayFvEIp3wuZ2ZnqXBnFOartzgbjFOeYtoEhncZFD0HKVNRqShuefDdgtmkt5p7NRsglwV40SrzApXmAcHBqB00nKrvg5bC")

export default function App() {
    logger.info('App Initialized Successfully')

    return (
        <Box
            className="wrapper position-relative"
            style={{
                background: '#ebebeb',
                height: '100vh',
                maxHeight: '-webkit-fill-available'
            }}
        >
            <Header/>

            <Routes>
                <Route path="/" element={<Navigate to="/registration"/>}/>
                <Route path="/registration" element={<RouteWrapper><Registration/></RouteWrapper>}/>
                <Route path="/confirmation" element={<RouteWrapper><Confirmation/></RouteWrapper>} />
                <Route path="/notice" element={<RouteWrapper><MailNotice/></RouteWrapper>} />
                <Route path="/payment/:id" element={<Payment stripe={stripe} />} />
                <Route path="/thankyou" element={<RouteWrapper><ThankYou stripe={stripe} /></RouteWrapper>} />
                <Route path="/location" element={<Location />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Box>
    );
}