import {
    Box,
    Step, StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
} from "@chakra-ui/react";
import {STEPS} from "../config/const";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const Progress = () => {
    const step = useSelector(state => state.progress.step);

    const { t } = useTranslation();

    return (
        <Stepper maxWidth='container.lg' width='100%' colorScheme='green' index={step}>
            {STEPS.map((step, index) => (
                <Step key={index}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>
                    <Box flexShrink='0'>
                        <StepDescription>{t('progress.'+(step.title))}</StepDescription>
                    </Box>
                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    )
}