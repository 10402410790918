const initialState = {
    step: 0,
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STEP':
            return {
                ...state,
                step: action.payload
            };
        default:
            return state;
    }
};

export default progressReducer;