import {Box, Button, Container, Heading, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation()

    return (
        <Container display="flex" justifyContent="center" alignItems="center" height="70vh">
            <Box className="text-center">
                <Heading as="h1" size="xl">{ t('not-found.title') }</Heading>
                <Text mt={4}>{ t('not-found.description') }</Text>
                    <a href="/registration">
                        <Button colorScheme="blue" mt={4}>{ t('not-found.site-link') }</Button>
                    </a>
            </Box>
        </Container>
    );
}