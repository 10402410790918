import {useToast} from "@chakra-ui/react";

const NotificationService = () => {
    const toast = useToast();

    const sendNotification = (title, status) => {
        toast({
            title,
            status,
            position: "top",
            duration: 2000,
            isClosable: true,
        });
    };

    return { sendNotification };
};

export default NotificationService;
