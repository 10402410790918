const initialState = {
    course: {}
};

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_COURSE':
            return {
                ...state,
                course: action.payload
            };
        default:
            return state;
    }
};

export default courseReducer;