import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import english from './locale/en/translation.json'
import japanese from './locale/ja/translation.json'
import chinese from './locale/zh/translation.json'
import korean from './locale/ko/translation.json'

const fallbackLng = ["en"];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {translation: english},
            ja: {translation: japanese},
            zh: {translation: chinese},
            ko: {translation: korean}
        },
        fallbackLng,
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage", "cookie"],
            lookupLocalStorage: 'i18nextLng',
            checkWhitelist: true,
        },
        debug: false,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;