import {Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {LANGUAGES} from "../config/const";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import Logger from "../utils/logger";

const logger = new Logger('Component|Language')

export const Language = () => {
    const focus = useRef(null);

    const { i18n } = useTranslation();

    const handleItemClick = (code) => {
        i18n.changeLanguage(code)
            .then(_ => {
                logger.info(`Language Changed To ${code} Successfully`)
            })
    };

    return (
        <Menu matchWidth={true} initialFocusRef={focus}>
            <MenuButton width={120}>
                {
                    LANGUAGES
                        .find(language => language.code === i18n.resolvedLanguage)
                        .label
                }
                <ChevronDownIcon marginLeft={2} />
            </MenuButton>
            <MenuList minW={"unset"} className="rounded-0">
                {LANGUAGES.map((language) => (
                    <MenuItem
                        onClick={() => handleItemClick(language.code)}
                        key={language.code}
                        ref={i18n.resolvedLanguage === language.code ? focus : null}
                    >
                        {language.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
