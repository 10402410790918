export const validate = (data) => {
    const errors = {};

    if (!data.name) {
        errors.name = "name";
    }

    if (data.phone && !/^\+?\d{1,3}?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(data.phone)) {
        errors.phone = "phone";
    }

    if (!data.course) {
        errors.course = "course";
    }

    if (!data.date) {
        errors.date = "date";
    }

    if (!data.time) {
        errors.time = "time";
    }

    if (!data.adult) {
        errors.adult = "adult";
    }

    return errors;
}