import {Container} from "@chakra-ui/react";
import {Progress} from "./Progress";

export const RouteWrapper = ({children}) => {
    return (
        <Container paddingBottom={5}>
            <Progress />
            <div className="card rounded-0 shadow mt-5">
                <div className="card-body px-4">{children}</div>
            </div>
        </Container>
    );
}
