import React, {useState, useEffect, useMemo} from "react";
import { Elements } from "@stripe/react-stripe-js";
import Checkout from "../components/Checkout";
import {Box, Container, Stat, StatHelpText, StatNumber} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import {getReservation} from "../service/api";
import {useDispatch} from "react-redux";
import {StepStatus} from "../config/const";
import {useTranslation} from "react-i18next";
import {Progress} from "../components/Progress";
import Logger from "../utils/logger";

const logger = new Logger('Page|Payment')

export const Payment = ({stripe}) => {
    const [reservation, setReservation] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isAlreadyPaid, setIsAlreadyPaid] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = useParams()
    const { i18n, t } = useTranslation();

    const secret = new URLSearchParams(window.location.search).get("secret")

    useEffect(() => {
        // urlにsecretパラメーターがない場合
        if (!secret) {
            logger.error('Secret Key Not Found')
            return navigate('/registration')
        }

        stripe.then(res => {
            res.retrievePaymentIntent(secret)
                .then(paymentIntentRes => {
                    // secret_keyが有効でない時
                    if (paymentIntentRes.error) {
                        logger.error('Secret Key Is Not Valid')
                        return navigate('/registration')
                    }

                    // 予約情報の取得
                    Promise.all([
                        getReservation(id),
                        dispatch({ type: 'SET_STEP', payload: StepStatus.Payment })])
                        .then(([data]) => {
                            // 既に支払済みの場合
                            if (paymentIntentRes.paymentIntent.status === "succeeded" || data.status === "succeeded") {
                                logger.info('Already Paid Reservation')
                                setIsAlreadyPaid(true)
                            }
                            setReservation(data)
                            setIsLoading(false)
                        })
                        .catch(error => {
                            logger.error('Fetching Reservation Info Error: ', error)
                            return navigate('/registration')
                        })
                });
        })
    }, [dispatch, id, navigate, secret, stripe])

    const options = useMemo(() => ({
        clientSecret: secret,
        locale: i18n.resolvedLanguage,
        appearance: {
            theme: 'stripe',
            variables: {
                borderRadius: '2px',
            }
        }
    }), [secret, i18n.resolvedLanguage]);

    return (
        !isLoading && <Container paddingBottom={5}>
            <Progress />
            <Box className="card rounded-0 shadow mt-5">
                <Box className="card-body px-4 py-5">
                    <Box className="mb-2">
                        <Stat>
                            <StatHelpText>{ t('reservation.name') }: {reservation?.name}</StatHelpText>
                            <StatHelpText>{ t('reservation.email') }: {reservation?.email}</StatHelpText>
                            <StatHelpText>{ t('reservation.phone') }: {reservation?.phone}</StatHelpText>
                            <StatHelpText>
                                { t('reservation.course') }: {reservation?.course?.name}
                            </StatHelpText>
                            <StatHelpText>{ t('reservation.adult') }: {reservation.adult}, { t('reservation.child') }: {reservation.child}</StatHelpText>
                            <StatNumber>¥{reservation?.amount}</StatNumber>
                        </Stat>
                    </Box>

                    {isAlreadyPaid ?
                        <Box className="d-inline border border-success rounded-1 text-success small py-1 px-2">
                            { t('payment.already-paid') }
                        </Box>
                    :
                        <Elements options={options} stripe={stripe}>
                            <Checkout/>
                        </Elements>
                    }
                </Box>
            </Box>
        </Container>
    );
}