import {useEffect, useState} from "react";
import {
    Box,
    Button, Heading,
    Icon,
    Input,
    InputGroup, InputLeftAddon,
    InputLeftElement, InputRightElement, Select,
    Textarea
} from "@chakra-ui/react";
import {Person} from "@mui/icons-material";
import {getCourses} from "../service/api";
import {validate} from "../config/validation";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorMessage} from "../components/ErrorMessage";
import Logger from "../utils/logger";
import {isPublicHoliday} from "../utils/helper";
import {StepStatus} from "../config/const";
import {PhoneIcon} from "@chakra-ui/icons";

const logger = new Logger('Page|Registration')

export const Registration = () => {
    const [courses, setCourses] = useState([])
    const [errors, setErrors] = useState({})

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const reservation = useSelector(state => state.reservation)
    const course = useSelector(state => state.course.course)

    const acCode = new URLSearchParams(window.location.search).get("ac_code")
    const idrCode = new URLSearchParams(window.location.search).get("idr_code")

    const { t } = useTranslation()

    useEffect(() => {
        if (acCode) {
            dispatch({ type: 'UPDATE_RESERVATION', payload: { name: 'ac_code', value: acCode }})
        }

        if (idrCode) {
            dispatch({ type: 'UPDATE_RESERVATION', payload: { name: 'idr_code', value: idrCode }})
        }

        getCourses()
            .then(res => res.data)
            .then(data => {
                logger.info('Courses: ', data);
                setCourses(data);
            })
            .catch(err => {
                logger.error('Fetching Course Error:', err)
            });
    }, [acCode, dispatch, idrCode])

    const handleInputChange = ({ target: { name, value } }) => {
        if (name === 'course') {
            const course = courses.find(c => c.id === Number(value))?? {};
            dispatch({ type: 'UPDATE_COURSE', payload: course})
        }

        dispatch({ type: 'UPDATE_RESERVATION', payload: { name, value }})
    }

    const submit = async (e) => {
        e.preventDefault()

        const errors = validate(reservation)
        setErrors(errors)

        if (Object.keys(errors).length) {
            return
        }

        let isHoliday = await isPublicHoliday(reservation.date)

        const adultAmount = reservation.adult ?
            (isHoliday ? course.adult_holiday_amount : course.adult_weekday_amount) * Number(reservation.adult) : 0;

        const childAmount = reservation.child ?
            (isHoliday ? course.child_holiday_amount : course.child_weekday_amount) * Number(reservation.child) : 0;

        const amount = adultAmount + childAmount;

        dispatch({ type: 'UPDATE_RESERVATION', payload: {
            name: 'amount',
            value: amount
        }})

        dispatch({ type: 'SET_STEP', payload: StepStatus.Payment })

        navigate('/confirmation')
    }

    return (
        <Box className="reservation-form">
            <Heading size="lg" textAlign="center" color="gray.500" py={3}>
                {t('reservation.title')}
            </Heading>
            <form onSubmit={submit}>
                <Box className="mb-2">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <Icon as={Person} color='pink.500' />
                        </InputLeftElement>
                        <Input
                            className="rounded-0"
                            isInvalid={errors.name}
                            name="name"
                            value={reservation.name}
                            placeholder={t('reservation.name')}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <ErrorMessage page='reservation' error={errors.name} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <PhoneIcon color='pink.500' />
                        </InputLeftElement>
                        <Input
                            className="rounded-0"
                            isInvalid={errors.phone}
                            name="phone"
                            value={reservation.phone}
                            placeholder={t('reservation.phone')}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <ErrorMessage page='reservation' error={errors.phone} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <Select
                            className="rounded-0"
                            isInvalid={errors.course}
                            name="course"
                            value={reservation.course}
                            onChange={handleInputChange}
                        >
                            <option value="">{t('reservation.select-course')}</option>
                            {courses.map(({ id, name }) => (
                               <option value={id} key={id}>{name}</option>
                            ))}
                        </Select>
                    </InputGroup>
                    {Object.keys(course).length > 0 &&
                        <>
                            <Box className="small text-secondary">ⓘ {t('reservation.weekdays')}: ※ {t('reservation.adult')}: {course.adult_weekday_amount}円, {t('reservation.child')}: {course.child_weekday_amount}円 ※</Box>
                            <Box className="small text-secondary">ⓘ {t('reservation.holidays')}: ※ {t('reservation.adult')}: {course.adult_holiday_amount}円, {t('reservation.child')}: {course.child_holiday_amount}円 ※</Box>
                        </>
                    }
                    <ErrorMessage page='reservation' error={errors.course} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <Input
                            className="rounded-0"
                            isInvalid={errors.date}
                            type="date"
                            name="date"
                            value={reservation.date}
                            min={moment().format('YYYY-MM-DD')}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <ErrorMessage page='reservation' error={errors.date} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <Select
                            borderRadius={0}
                            isInvalid={errors.time}
                            name="time"
                            value={reservation.time}
                            onChange={handleInputChange}
                        >
                            <option value="">{t('reservation.time')}</option>
                            {[...Array(12)].map((_, index) => (
                                <option value={index + 10} key={index}>{index + 10} : 00</option>
                            ))}
                        </Select>
                    </InputGroup>
                    <ErrorMessage page='reservation' error={errors.time} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <InputLeftAddon className="rounded-0 bg-light">{t('reservation.adult')}</InputLeftAddon>
                        <Input
                            className="rounded-0"
                            isInvalid={errors.adult}
                            type="number"
                            name="adult"
                            value={reservation.adult}
                            onChange={handleInputChange}
                        />
                        <InputRightElement width='4.5rem' marginEnd={2}>
                            <Button h='1.75rem' size='sm' marginEnd={2} onClick={() => {
                                dispatch({ type: 'UPDATE_RESERVATION', payload: { name:'adult', value: (reservation.adult? Number(reservation.adult): 0) + 1 }})
                            }}>+</Button>
                            <Button h='1.75rem' size='sm' onClick={() => {
                                if (reservation.adult > 0) {
                                    dispatch({ type: 'UPDATE_RESERVATION', payload: { name:'adult', value: (reservation.adult? Number(reservation.adult): 0) - 1 }})
                                }
                            }}>-</Button>
                        </InputRightElement>
                    </InputGroup>
                    <Box className="small text-secondary">ⓘ {t('reservation.adult-info')}</Box>
                    <ErrorMessage page='reservation' error={errors.adult} />
                </Box>

                <Box className="mb-2">
                    <InputGroup>
                        <InputLeftAddon className="rounded-0 bg-light">{t('reservation.child')}</InputLeftAddon>
                        <Input
                            className="rounded-0"
                            type="number"
                            name="child"
                            value={reservation.child}
                            onChange={handleInputChange}
                        />
                        <InputRightElement width='4.5rem' marginEnd={2}>
                            <Button h='1.75rem' size='sm' marginEnd={2} onClick={() => {
                                dispatch({type: 'UPDATE_RESERVATION',
                                    payload: {
                                        name: 'child',
                                        value: (reservation.child ? Number(reservation.child) : 0) + 1
                                    }
                                })
                            }}>+</Button>
                            <Button h='1.75rem' size='sm' onClick={() => {
                                if (reservation.child > 0) {
                                    dispatch({type: 'UPDATE_RESERVATION',
                                        payload: {
                                            name: 'child',
                                            value: (reservation.child ? Number(reservation.child) : 0) - 1
                                        }
                                    })
                                }
                            }}>-</Button>
                        </InputRightElement>
                    </InputGroup>
                    <Box className="small text-secondary">ⓘ {t('reservation.child-info')}</Box>
                </Box>

                <Box className="mb-2">
                <Textarea
                        className="rounded-0"
                        name="remarks"
                        rows={4}
                        maxLength={250}
                        placeholder={t('reservation.remarks')}
                        value={reservation.remarks}
                        onChange={handleInputChange}
                    />
                </Box>

                <Box className="text-center">
                    <Button
                        className="rounded-0 w-100"
                        colorScheme='pink'
                        color='white'
                        type='submit'
                    >
                        {t('reservation.proceed')}
                    </Button>
                </Box>
            </form>
        </Box>
    )
}