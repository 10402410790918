import Logger from "../utils/logger";

const logger = new Logger('Service|Api')

const baseURL = process.env.REACT_APP_API_URL;

const headers = {
    'Content-Type': 'application/json',
}

const handleResponse = (response) => {
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response.json();
};

const post = (url, body) => {
    logger.info('Post Request: ', url)
    logger.info('Params: ', body)

    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }).then(handleResponse);
};

const get = (url) => {
    logger.info('Get Request: ', url)

    return fetch(url, {
        method: 'GET',
        headers,
    }).then(handleResponse);
};

export const checkout = (reservation) => {
    return post(`${baseURL}/checkout`, reservation)
};

export const sendEmail = (data, email, language) => {
    const params = {
        subject: '川下りTHC',
        to: email,
        id: data.id,
        language,
        secret: data.clientSecret
    }
    return post(`${baseURL}/send-mail`, params)
}

export const getCourses = () => {
    return get(`${baseURL}/course`)
}

export const getReservation = (id) => {
    return get(`${baseURL}/reservation/${id}`)
}

export const updateReservation = (id, language) => {
    return post(`${baseURL}/reservation/${id}`, {language})
}

export const getPublicHoliday = (year) => {
    return fetch(`https://holidays-jp.github.io/api/v1/${year}/date.json`)
        .then(handleResponse)
}