import {Box, Image} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {StepStatus} from "../config/const";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Logger from "../utils/logger";

const logger = new Logger('Page|ThankYou')

export const ThankYou = ({stripe}) => {
    const reservation = useSelector(state => state.reservation)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { t } = useTranslation()

    useEffect(() => {
        if (!reservation.name) {
            logger.error('Unauthorized Route Accessed')
            return navigate('/registration')
        }

        dispatch({ type: 'SET_STEP', payload: StepStatus.Completed})
    }, [dispatch, navigate, reservation.name])

    return (
        <Box className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="text-secondary mb-5">{ t('thank-you.success') }</h4>
            <Image
                boxSize='100px'
                src='/images/check-mark.png'
                alt='Mail Sent Icon'
            />
            <p className="text-secondary small mt-5">{ t('thank-you.message') }</p>
        </Box>
    )
}
