import { Image } from '@chakra-ui/react'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Logger from "../utils/logger";

const logger = new Logger('Page|MailNotice')

export const MailNotice = () => {
    const navigate = useNavigate()
    const reservation = useSelector(state => state.reservation)

    const { t } = useTranslation()

    useEffect(() => {
        if (!reservation.email) {
            logger.error('Reservation State Expired')
            navigate('/registration')
        }
    }, [navigate, reservation.email])

    return (
        <div className="mail-complete-info">
            <div className="d-flex justify-content-center">
                <Image
                    boxSize='70px'
                    src='/images/email.png'
                    alt='Mail Sent Icon'
                />
            </div>
            <h5 className="text-center text-success mt-3">{ t('mail-delivered.title') }</h5>
            <p className="text-center text-secondary small fw-bold">{reservation.email}</p>
            <div className="small border p-3" style={{'background': '#ebebeb'}}>
                <h6 className="text-danger fw-bold mt-3">{ t('mail-delivered.subtitle') }</h6>
                <p className="mt-3">{ t('mail-delivered.content-first') }</p>
                <p>{ t('mail-delivered.content-second') }</p>
                <p>{ t('mail-delivered.content-third') }</p>
                <p className="text-danger mt-2">※{ t('mail-delivered.warning') }</p>
            </div>
        </div>
    )
}
