import { combineReducers } from 'redux';
import progressReducer from "./progress";
import reservationReducer from "./reservation";
import courseReducer from "./course";

const rootReducer = combineReducers({
    reservation: reservationReducer,
    progress: progressReducer,
    course: courseReducer
});

export default rootReducer;
