import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {Button, Spinner} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import Logger from "../utils/logger";
import {updateReservation} from "../service/api";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const logger = new Logger('Component|Checkout')

export default function Checkout() {
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret")

        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!")
                    break
                case "processing":
                    setMessage("Your payment is processing.")
                    break
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.")
                    break
                default:
                    setMessage("Something went wrong.")
                    break
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            logger.info('Stripe Is Not Loaded | Payment Element Not Found')
            return;
        }

        setIsLoading(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
        });

        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.")
            }
        }

        if (paymentIntent) {
            logger.info('Payment Succeeded', paymentIntent)
            updateReservation(paymentIntent.id, i18n.resolvedLanguage)
                .then(data => {
                    logger.info('Reservation Status Update Succeeded', data)
                    dispatch({ type: 'UPDATE_RESERVATION', payload: { name: 'name', value: data.name }})
                    navigate('/thankyou')
                })
                .catch(err => {
                    logger.error('Reservation Status Update Failed', err)
                })
        }

        setIsLoading(false)
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions}/>
            <Button type="submit" borderRadius={0} width='100%' marginTop={5} colorScheme='pink' color='white' isDisabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isLoading?<Spinner marginLeft={5} />: t('payment.pay-now-btn')}
                </span>
            </Button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}