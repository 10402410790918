export default class Logger {
  debug;
  info;
  warn;
  error;

  constructor(name) {

    if (process.env.NODE_ENV === 'production') {
      this.debug = () => { };
      this.info = () => { };
      this.warn = () => { };
      this.error = () => { };
      return;
    }

    this.info = console.log.bind(console, '[%s]', name);
    this.debug = console.debug.bind(console, '[%s]', name);
    this.warn = console.warn.bind(console, '[%s]', name);
    this.error = console.error.bind(console, '[%s]', name);
  }
}
